import { Stop } from "@/models/dto"
import { EtaJourneyEvent, ArrivalJourneyEvent, LiveTripEvent } from "@/models/dto/LiveTripEvent"
import { DateTime } from "luxon"

export const buildLiveTripEventBody = (evnt: EtaJourneyEvent | ArrivalJourneyEvent | LiveTripEvent, journey) => {
  const { type } = evnt

  switch (type) {
    case 'LATE_ETA':
      return buildLateEtaEventBody(evnt as EtaJourneyEvent, journey)
    case 'ON_TIME_ETA':
      return buildOnTimeEtaEventBody(evnt as EtaJourneyEvent, journey)
    case 'LATE_ARRIVAL':
      return buildLateArrivalEventBody(evnt as ArrivalJourneyEvent, journey)
    case 'ON_TIME_ARRIVAL':
      return buildOnTimeArrivalEventBody(evnt as ArrivalJourneyEvent, journey)
    default:
      return ''
  }
}

export const buildLiveTripEventHeader = (evnt: EtaJourneyEvent | LiveTripEvent, journey) => {
  const { type } = evnt

  switch (type) {
    case 'LATE_ETA':
      return buildLateEtaHeader(evnt as EtaJourneyEvent, journey)
    case 'ON_TIME_ETA':
      return buildOnTimeEtaHeader(evnt as EtaJourneyEvent, journey)
    case 'LATE_ARRIVAL':
      return buildLateArrivalHeader(evnt as ArrivalJourneyEvent, journey)
    case 'ON_TIME_ARRIVAL':
      return buildOnTimeArrivalHeader(evnt as ArrivalJourneyEvent, journey)
    default:
      return ''
  }
}

const buildLateEtaEventBody = (evnt: EtaJourneyEvent, journey): string => {
  const { waypointId, eta } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error(`Stop not found for waypointId: ${waypointId}`)
  }

  const timeZone = stop.address.timeZone
  const addressName = stop.address.title || stop.address.addressName
  const time = DateTime.fromISO(eta).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)

  // TODO: Move this calculation to the back-end
  const stopPickupTime = stop.dropoffDatetime || stop.pickupDatetime
  const { minutes } = DateTime.fromISO(stopPickupTime).diff(DateTime.fromISO(eta), 'minutes').toObject()
  const minutesLate = Math.abs(minutes)
  return `ETA to ${addressName} is ${minutesLate} minutes late at ${time}`
}

const buildLateEtaHeader = (evnt: EtaJourneyEvent, journey): string => {
  const { waypointId, eta } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const time = DateTime.fromISO(eta).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)
  return `Late ETA, ${time}`
}

const buildOnTimeEtaEventBody = (evnt: EtaJourneyEvent, journey): string => {
  const { waypointId, eta } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const addressName = stop.address.title || stop.address.addressName
  const time = DateTime.fromISO(eta).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)

  return `ETA to ${addressName} is on-time at ${time}`
}

const buildOnTimeEtaHeader = (evnt: EtaJourneyEvent, journey): string => {
  const { waypointId, eta } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const time = DateTime.fromISO(eta).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)
  return `On-Time ETA, ${time}`
}

const buildLateArrivalEventBody = (evnt: ArrivalJourneyEvent, journey): string => {
  const { waypointId, arrivedOn } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const addressName = stop.address.title || stop.address.addressName
  const time = DateTime.fromISO(arrivedOn).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)

  const stopPickupTime = stop.dropoffDatetime || stop.pickupDatetime
  const { minutes } = DateTime.fromISO(stopPickupTime).diff(DateTime.fromISO(arrivedOn), 'minutes').toObject()
  const minutesLate = Math.abs(minutes)

  return `Arrival to ${addressName} was ${minutesLate} minutes late at ${time}.`
}

const buildLateArrivalHeader = (evnt: ArrivalJourneyEvent, journey): string => {
  const { waypointId, arrivedOn } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const time = DateTime.fromISO(arrivedOn).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)
  return `Arrived Late, ${time}`
}

const buildOnTimeArrivalEventBody = (evnt: ArrivalJourneyEvent, journey): string => {
  const { waypointId, arrivedOn } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const addressName = stop.address.title || stop.address.addressName
  const time = DateTime.fromISO(arrivedOn).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)

  return `Arrival to ${addressName} was on time at ${time}.`
}

const buildOnTimeArrivalHeader = (evnt: ArrivalJourneyEvent, journey): string => {
  const { waypointId, arrivedOn } = evnt
  const { stops } = journey
  const stop = stops.find(s => s.waypointId === waypointId)
  if (!stop) {
    throw new Error('Stop not found')
  }

  const timeZone = stop.address.timeZone
  const time = DateTime.fromISO(arrivedOn).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)
  return `Arrived On Time, ${time}`
}
