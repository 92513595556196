
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { buildLiveTripEventBody, buildLiveTripEventHeader } from '@/utils/liveTripEvents'
@Component({})
export default class LiveTripEventCard extends Vue {
  @Prop({ required: true }) event!: any
  @Prop({ required: true }) journey!: any
  @Prop({ required: false, type: Boolean }) isActive!: boolean

  get heading() {
    return buildLiveTripEventHeader(this.event, this.journey)
  }

  get body() {
    return buildLiveTripEventBody(this.event, this.journey)
  }

  get reminderText(): string {
    if (!this.event.alerts?.length) {
      return null
    }
    const { snoozedUntil, status }  = this.event.alerts[0]
    if (status !== 'SNOOZED' || !snoozedUntil) {
      return null
    }

    const reminderTime = DateTime.fromISO(snoozedUntil).toFormat('hh:mm a')
    return `Reminder set, ${reminderTime}`
  }

  get occurredOnTimestamp() {
    return DateTime.fromISO(this.event.occurredOn).toFormat('MM/dd, h:mm a')
  }

  handleAlertClick() {
    this.$emit('alert-click', this.event)
  }
}
